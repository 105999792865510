<template>
  <div class="x-content">
    <!-- 头部 -->
    <div class="x-head">
      <van-image width="100%" fit="contain" :src="images.MembershipCenter" />
      <!-- 返回按钮 -->
      <!-- 头部 -->
      <div class="x-hed">
        <van-nav-bar
          class="van-nav-bar"
          title="会员中心"
          left-text="返回"
          right-text=""
          color="#2387E3"
          left-arrow
          @click-left="onClickLeft"
        />
      </div>
      <!-- 浮动成 -->
      <div class="x-head-card">
        <van-button type="default" class="x-promotion-code" @click="onShowCode"
          >我的推广码</van-button
        >
        <div class="x-card-list">
          <div class="x-card-li">
            <div class="x-count">{{ deal.totalOrders || 0 }}</div>
            <div class="x-table">今日成交订单</div>
          </div>
          <div class="x-card-li">
            <div class="x-count">{{ deal.totalFee || 0 }}</div>
            <div class="x-table">今日成交金额</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 我的列表 -->
    <div class="x-list-data">
      <div class="x-title">授权给我的产品</div>
      <div class="x-ul">
        <div class="x-li" v-for="item in listData" :key="item.plantId">
          {{ item.plantName }}
        </div>
      </div>
    </div>

    <!--  -->
    <div class="x-nodata">没有更多了～</div>

    <!-- 弹窗 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block" @click.stop id="shareHtml">
          <div class="x-title">推广码：{{ extensionCode || "" }}</div>
          <div class="x-code">
            <!-- <van-image width="100%" fit="contain" :src="imgURL" /> -->
            <div
              class="qrcode"
              ref="qrCodeUrl"
              :style="{ width: '100%' }"
            ></div>
          </div>
          <van-button type="primary" class="x-submit" @click="createPoster"
            >长按图片保存</van-button
          >
          <div class="x-clsoe" @click="show = false">
            <img :src="images.clsoe" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import download from "downloadjs";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import MembershipCenter from "../assets/MembershipCenter.png";
import Closingorder from "../assets/Closingorder.png";
import Transactionamount from "../assets/Transactionamount.png";
import clsoe from "../assets/clsoe.png";

export default {
  data() {
    return {
      deal: {
        totalFee: 0,
        totalOrders: 0
      },

      extensionCode: "",
      show: false,
      images: {
        MembershipCenter,
        Closingorder,
        Transactionamount,
        clsoe
      },
      listData: [
        // { title: "心脏外科手术安心保障计划" },
      ],
      imgURL: ""
    };
  },
  mounted() {
    document.title = "会员中心";
    this.initData();
  },
  methods: {
    onClickLeft() {
      this.$router.push("/login");
    },
    onShowCode() {
      this.show = true;
    },
    // downloadIamge(imgsrc, name) {
    //   //下载图片地址和图片名
    //   var image = new Image();
    //   // 解决跨域 Canvas 污染问题
    //   image.setAttribute("crossOrigin", "anonymous");
    //   image.onload = function () {
    //     var canvas = document.createElement("canvas");
    //     canvas.width = image.width;
    //     canvas.height = image.height;
    //     var context = canvas.getContext("2d");
    //     context.drawImage(image, 0, 0, image.width, image.height);
    //     var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    //     var a = document.createElement("a"); // 生成一个a元素
    //     var event = new MouseEvent("click"); // 创建一个单击事件
    //     a.download = name || "photo"; // 设置图片名称
    //     a.href = url; // 将生成的URL设置为a.href属性
    //     a.dispatchEvent(event); // 触发a的单击事件
    //   };
    //   image.src = imgsrc;
    // },

    downsImage() {},

    //获取数据
    initData() {
      // 查询登录用户推广码
      this.$api.getPromotionCode().then((res) => {
        if (res.code != 1000) {
          // this.$Dialog.alert({ message: res.message });
          return;
        }
        let code = res.data.invitationcode;
        this.extensionCode = code;

        this.creatQrCode("https://www.91xbw.net/index/" + code);
      });

      // 查询当前销售营业额
      this.$api.queryBusinessData().then((res) => {
        if (res.code != 1000) {
          // this.$Dialog.alert({ message: res.message });
          return;
        }
        this.deal = res.data;
      });

      // 查询当前可推广多少产品
      this.$api.queryProduct().then((res) => {
        if (res.code != 1000) {
          // this.$Dialog.alert({ message: res.message });
          return;
        }
        this.listData = res.data;
      });
    },

    // 生成二维码
    creatQrCode(qrCodeUrl) {
      new QRCode(this.$refs.qrCodeUrl, {
        text: qrCodeUrl, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H
      });
    },

    // 下载二维码
    createPoster() {
      const dom = document.getElementById("shareHtml");
      html2canvas(dom, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        onclone(doc) {
          let e = doc.querySelector("#shareHtml");
          e.style.display = "block";
        }
      }).then(function (canvas) {
        let shareImg = canvas.toDataURL("image/png");
        download(shareImg);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.x-hed {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 16px;

  .van-nav-bar {
    background: none;
    border: none;
    color: white;

    ::v-deep .van-icon {
      color: #fff;
    }
    ::v-deep .van-nav-bar__text {
      color: #fff;
    }
    ::v-deep .van-ellipsis {
      color: #fff;
    }
  }
  .van-nav-bar::after {
    background: none;
    border: none;
    color: white;
  }
}

.x-head {
  padding-bottom: 72px;
  box-sizing: border-box;
  background-color: white;
  position: relative;

  .x-head-card {
    position: absolute;
    top: 0px;
    bottom: 24px;
    left: 0px;
    right: 0px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .x-promotion-code {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border-radius: 4px;
      border: none;
      font-weight: bold;
    }

    .x-card-list {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .x-card-li {
        width: 48%;
        height: 96px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .x-count {
          font-size: 28px;
          font-weight: bold;
          color: #ffffff;
        }
        .x-table {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 4px;
        }
      }

      .x-card-li:nth-child(1) {
        background-image: url("../assets/Closingorder.png");
        background-position: 0% 0%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .x-card-li:nth-child(2) {
        background-image: url("../assets/Transactionamount.png");
        background-position: 0% 0%;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.x-list-data {
  padding: 16px;
  padding-top: 22px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 10px;

  .x-title {
    font-size: 16px;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 10px;
    box-sizing: border-box;
  }

  .x-ul {
    .x-li {
      padding: 16px 0px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      color: #4d4d4d;
      border-top: 1px solid #f8f8f8;
    }
    .x-li:nth-child(1) {
      border: none;
    }
  }
}

.x-nodata {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  padding: 28px 0px;
  padding-bottom: 50px;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    width: 100%;
    padding: 24px 16px;
    box-sizing: border-box;
    margin: 0px 40px;
    margin-bottom: 50px;
    background-color: #fff;
    text-align: center;
    position: relative;

    .x-title {
      font-size: 14px;
      font-weight: bold;
      color: #4d4d4d;
    }

    .x-code {
      margin: auto;
      margin-top: 24px;
      width: 150px;
      height: 150px;
      background-color: black;
    }

    .x-submit {
      width: 220px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
      border: none;
      margin-top: 35px;
    }

    .x-clsoe {
      position: absolute;
      bottom: -49px;
      left: 0px;
      right: 0px;

      img {
        width: 26px;
      }
    }
  }
}
</style>
